
import {
  DeliveryCalendarDayType,
  OptionType,
  OrderState,
  PaymentMethodType,
  PropertyType,
  TaxType,
} from "@/graphql/client";
import { Component, Vue } from "vue-property-decorator";
import typeService from "@/service/typeService";
import moment from "moment";
import numeral from "numeral";

/**
 * 日付を指定した形式の文字列にして返します.
 */
const toPercent = (value: number): number => {
  if (!value) return 0;
  return value * 100;
};

/**
 * 日付を指定した形式の文字列にして返します.
 */
const formatDate = (value: string, format?: string): string => {
  format = format ? format : "YYYY/MM/DD";
  return value ? moment(value).format(format) : "なし";
};

/**
 * 日付を「YYYY/MM/DD HH:mm」形式の文字列にして返します.
 */
const formatDateTime = (value: string): string => {
  return formatDate(value, "YYYY/MM/DD HH:mm:ss");
};

/**
 * 数値をカンマ区切りの文字列にして返します.
 */
const formatPrice = (value: string): string => {
  return numeral(value).format("0,0");
};

/**
 * 値がTrueの場合は「有効」を、Falseの場合は「無効」を返します.
 */
const getIsAvailableLabel = (value: boolean): string => {
  if (value) return "有効";
  return "無効";
};

//---------------------------
// for Enum
//---------------------------
/**
 * 税種別のラベルを返します.
 */
const getTaxTypeLabel = (item: TaxType): string => {
  return typeService.getTaxTypeLabel(item);
};

/**
 * プロパティ種別のラベルを返します.
 */
const getPropertyTypeLabel = (item: PropertyType): string => {
  return typeService.getPropertyTypeLabel(item);
};

/**
 * 日付種別のラベルを返します.
 */
const getDeliveryCalendarDayTypeLabel = (
  item: DeliveryCalendarDayType
): string => {
  return typeService.getDeliveryCalendarDayTypeLabel(item);
};

/**
 * オプション種別のラベルを返します.
 */
const getOptionTypeLabel = (item: OptionType): string => {
  return typeService.getOptionTypeLabel(item);
};

/**
 * 注文ステータスのラベルを返します.
 */
const getOrderStateLabel = (item: OrderState | undefined): string => {
  return typeService.getOrderStateLabel(item);
};

/**
 * 支払方法種別のラベルを返します.
 */
const getPaymentMethodTypeLabel = (item: PaymentMethodType): string => {
  return typeService.getPaymentMethodTypeLabel(item);
};

@Component({
  filters: {
    toPercent: toPercent,
    formatDate: formatDate,
    formatDateTime: formatDateTime,
    formatPrice: formatPrice,
    //for enum
    getTaxTypeLabel: getTaxTypeLabel,
    getPropertyTypeLabel: getPropertyTypeLabel,
    getDeliveryCalendarDayTypeLabel: getDeliveryCalendarDayTypeLabel,
    getOptionTypeLabel: getOptionTypeLabel,
    getOrderStateLabel: getOrderStateLabel,
    getPaymentMethodTypeLabel: getPaymentMethodTypeLabel,
  },
})
export default class MixinFormatter extends Vue {
  /**
  /**
   * 指定した数値をパーセンテージ表示に変換します
   */
  public toPercent(value: number): number {
    return toPercent(value);
  }
  /**
  /**
   * 日付を指定した形式の文字列にして返します.
   */
  public formatDate(value: string, format?: string): string {
    return formatDate(value, format);
  }
  /**
   * 日付を「YYYY/MM/DD HH:mm」形式の文字列にして返します.
   */
  public formatDateTime(value: string): string {
    return formatDateTime(value);
  }

  /**
   * 数値をカンマ区切りの文字列にして返します.
   */
  public formatPrice(value: string): string {
    return formatPrice(value);
  }

  /**
   * 値がTrueの場合は「有効」を、Falseの場合は「無効」を返します.
   */
  public getIsAvailableLabel(value: boolean): string {
    return getIsAvailableLabel(value);
  }

  //---------------------------
  // for Enum
  //---------------------------
  /**
   * 税種別のラベルを返します.
   */
  public getTaxTypeLabel(item: TaxType): string {
    return getTaxTypeLabel(item);
  }

  /**
   * プロパティ種別のラベルを返します.
   */
  public getPropertyTypeLabel(item: PropertyType): string {
    return getPropertyTypeLabel(item);
  }

  /**
   * 日付種別のラベルを返します.
   */
  public getDeliveryCalendarDayTypeLabel(
    item: DeliveryCalendarDayType
  ): string {
    return getDeliveryCalendarDayTypeLabel(item);
  }

  /**
   * オプション種別のラベルを返します.
   */
  public getOptionTypeLabel(item: OptionType): string {
    return getOptionTypeLabel(item);
  }

  /**
   * 注文ステータスのラベルを返します.
   */
  public getOrderStateLabel(item: OrderState | undefined): string {
    return getOrderStateLabel(item);
  }

  /**
   * 支払方法種別のラベルを返します.
   */
  public getPaymentMethodTypeLabel(item: PaymentMethodType): string {
    return getPaymentMethodTypeLabel(item);
  }
}
